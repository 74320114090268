import React from 'react';
import SampleSizeCTA from '../../../Contentful/SampleSize';
import Footer from '../../../components/Footer';
import WorkImage from '../../../images/work-images/work1.png';
import { DATE_ONLY, DateTimeConverter } from '../../../utils/DateTImeConverter';
import richTextRenderer from '../../../utils/richTextRenderer';
import './style.scss';
import { graphql } from 'gatsby';

const WorkDetails = ({ data }) => {
    console.log({ data })
    const { allContentfulWorks: { nodes } } = data ?? {};
    const { title, category, pageInfo, createdAt, pageDescription: { raw: mainPageDescription } } = nodes[0] ?? [];
    return (
        <div className='work_details_container col'>
            <div className='col w-80 mx-auto'>
                <div className='col '>
                    <div className='row w-100 jt-cn'>
                        <span className='text-50 text-centre font-bold'>{title ?? `Default Title`}</span>
                    </div>
                    <div className='row w-100 jt-cn'>
                        <span className='font-bold'>{DateTimeConverter(createdAt, DATE_ONLY) ?? `Default Date`}</span>
                    </div>
                </div>

                <div className='row jt-bt my-auto mt-5'>
                    <div className='col text-centre card-bg-definition'>
                        <span className='font-bold'>Created</span>
                        <span>{DateTimeConverter(createdAt, DATE_ONLY) ?? `Default Date`}</span>
                    </div>
                    <div className='col text-centre card-bg-definition'>
                        <span className='font-bold'>Timeline</span>
                        <span>2 months</span>
                    </div>
                    <div className='col text-centre card-bg-definition'>
                        <span className='font-bold'>Industry</span>
                        <span>{category}</span>
                    </div>
                    <div className='col text-centre card-bg-definition'>
                        <span className='font-bold'>Feature Favorite</span>
                        <span>Speed & Simplicity</span>
                    </div>
                </div>

                <div className='row w-80 jt-cn'>
                    <img className='image-content' src={WorkImage} alt='test' />
                </div>


                <div className='col my-5'>
                    <div>
                        <span className='text-30 font-bold'>{pageInfo}</span>
                    </div>
                    <div>
                        <p className='text-20'>{richTextRenderer(JSON.parse(mainPageDescription))}</p>
                    </div>
                </div>

            </div>
            <div>
                <SampleSizeCTA buttonTitle={'Create Survey'} />
                <Footer />
            </div>
        </div>
    )
}

export default WorkDetails

export const query = graphql`query GetWork($slug : String) {
    allContentfulWorks(filter: {slug: {in: [$slug]}}) {
      nodes {
        slug
        title
        pageInfo
        createdAt
        updatedAt
        category
        pageDescription {
          raw
        }
        pageInfo
      }
    }
  }`;
