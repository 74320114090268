export const DateTimeConverter = (
  arg,
  type
) => {
  if (arg !== undefined) {
    const changedDate = new Date(arg.toString());
    if (type === DATE_ONLY) {
      return changedDate.toLocaleString();
    } else if (type === DATE_TIME_ONLY) {
      return changedDate.toLocaleString();
    } else {
      return changedDate.toLocaleString();
    }
  } else {
    return "N/A";
  }
};

/* Constants */
export const DATE_ONLY = "DATE";
export const DATE_TIME_ONLY = "DATE_TIME";
export const TIME_ONLY = "TIME";
